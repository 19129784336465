import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import Progressbar from './ProgressBar';
import { Button } from 'react-bootstrap';

function ResponseModal(props){

    const navigate=useNavigate();
    const handle_Abort = () => {
         navigate(props.path);
        props.onHide(false)
        props.setRunning(false);
    }

    return(
        <>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        ><Modal.Body className='d-flex flex-column justify-content-evenly '>
                {
                    props.modalType === 'start' ? <><div className="text_wrapper">
                    </div>
                        <div className="d-flex justify-content-center justify-content-evenly  my-1">
                            <Progressbar progress={100} />
                        </div>
                        <div className="d-flex justify-content-center justify-content-evenly  my-1">
                                <Button className='submit_btn ms-2' onClick={handle_Abort}>Cancel</Button>
                                {/* <Link to='/start_PCR'>
                                    <button className='myBtn me-2'  >START PCR </button>
                                </Link> */}
                            </div>
                        </>

                        : <>
                        <div className="text_wrapper">
                            <p className="text-center mb-0 font-weight-bold">{props.text}</p>
                        </div>
                            <div className="d-flex justify-content-center justify-content-evenly  my-1">
                                <button className='submit_btn ms-2' onClick={handle_Abort}>Ok</button>
                                {/* <Link to='/start_PCR'>
                                    <button className='myBtn me-2'  >START PCR </button>
                                </Link> */}
                            </div></>
                }
            </Modal.Body>



        </Modal>

    </>
    )

}
export default ResponseModal;