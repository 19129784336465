import { Link } from "react-router-dom";
import logo from "./assets/logo2.png"
export const Header=()=>{
    const states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhatisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttrakhand",
        "West Bengal",
        "Chandigarh",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
      ];


    return(
        <div style={{backgroundColor:"white",height:"350px"}} > 
<Link style={{textDecoration:"none"}} to={"/"}><div style={{display:"flex",flexDirection:"row"}}>
 <img src={logo} height="150" alt="logo" style={{width:"40%"}}/>

<div className=' myheader' style={{fontStyle:"italic"}}>Bharat College Finder</div>
</div></Link>
<div style={{ backgroundColor: "cyan", height: "75px", display: "flex", flexDirection: "row", overflowX: "auto", whiteSpace: "nowrap",scrollbarWidth: "thin", scrollbarColor: "yellow transparent"}}>
        {
            states.map((mystate)=>(
              <><Link style={{textDecoration:"none"}} to={`/college/${mystate}`}>
                <p onClick={() => {
                    // navigate(`/college/${mystate}`);
                } } className="states">{mystate}</p>
                </Link>
                </>
            ))
        }
</div>
{/* <iframe
        title="Google Ads"
        src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        style={{ display: 'block', marginTop: '20px' }}
        data-ad-client="pub-3044699811175106"
        async
      ></iframe> */}
</div>
    )
}