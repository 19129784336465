import { useParams } from "react-router-dom/dist";
import { Header } from "./Components/Header"
import { useEffect } from "react";

const ShowCollegeData=()=>{
   

  const {collname,univname,statename,districtname,collegetype}=useParams();
    return(
        <div>
        <Header />
        <div className="college-details justify-content-center">
        <div className="college-containertwo">
        <div className="college-cardt">
           <div className="col header_bodaerstyle">
                       
      <h3>{collname}</h3>
      <button  className="silverbtn">State Name - {statename}</button>
                            
     
      </div>
      <div className="cenert-border">
      <p><strong>College Type:</strong> {collegetype}</p>
      <p><strong>District:</strong> {districtname}</p>
      <p><strong>University:</strong> {univname}</p>
      </div>
    
    </div>
            </div>
        </div>   
        </div>
    )
}
export default ShowCollegeData;