
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';

import College from './College';
import ShowCollegeData from './ShowCollegeData';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/college/:mystatename'  element={<College />}/>
      <Route path='/ShowCollegeDetails/:collname/:univname/:statename/:districtname/:collegetype' element={<ShowCollegeData />}/>
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
