import React from 'react';
import '../App.css'; // Import your CSS file for styling
import { Link, useNavigate } from 'react-router-dom/dist';

export const CollegeCard = ({ college }) => {
  const navigate=useNavigate();
  return (
    
    <div  className="college-card">
      <Link style={{textDecoration:"none",color:"black"}}  to={`/ShowCollegeDetails/${college.College_Name}/${college.University_Name}/${college.State_Name}/${college.District_Name}/${college.College_Type}`}>
           <div className="col header_bodaerstyle">
                       
      <h3>{college.College_Name}</h3>
      <button  className="silverbtn">State Name - {college.State_Name}</button>
                            
     
      </div>
      <div className="cenert-border">
      <p><strong>College Type:</strong> {college.College_Type}</p>
      <p><strong>District:</strong> {college.District_Name}</p>
      <p><strong>University:</strong> {college.University_Name}</p>
      </div>
      </Link>
    </div>

  );
};