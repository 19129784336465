import { Header } from "./Components/Header";

import logo from "./Components/assets/logo2.png"
import { allstates } from './Components/AllStates';
import { Link } from "react-router-dom";
const Home=()=>{

    return(
        <div className="App">
            <Header />
            <div className="college-container">
                 <img  src={logo} height="290" alt="logo" style={{width:"100%",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}}/>
                 <div className="cenert-border">
                        {allstates.map((state)=>(
                             <><Link style={{textDecoration:"none"}} to={`/college/${state}`}>
                             <p onClick={() => {
                                 // navigate(`/college/${mystate}`);
                             } } className="states">{state}</p>
                             </Link>
                             </>
                        ))}
                 </div>
            </div>
        </div>
    );
}
export default Home;