import { useParams } from "react-router-dom";
import { Header } from "./Components/Header";
import { useEffect, useState } from "react";
import { CollegeCard } from "./Components/CollegeData";
import ResponseModal from "./Components/ResponseModal";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom/dist";


const College =()=>{
    const {mystatename}=useParams();
    const [data, setData] = useState([]);
    const [modalType,setModalType]=useState('');
    const [modalShow, setModalShow] = useState(false);
    const [currentItems ,setcurrentitems]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);
    useEffect(()=>{

        const fetchcolleges = async () => {
            const apiurl = "https://thk9n7oewa.execute-api.ap-south-1.amazonaws.com/firstdeploy/getcollegebystate?State_Name=" + mystatename;
            
            try {

               setModalShow(true)
                setModalType("start")
                const response = await fetch(apiurl);
                const mydata = await response.json();
                const indexOfLastItem = currentPage * itemsPerPage;
                const indexOfFirstItem = indexOfLastItem - itemsPerPage;
                const mycurrentItems =  mydata.slice(indexOfFirstItem, indexOfLastItem) ;
                console.log("data",mycurrentItems)
                setcurrentitems(mycurrentItems)
               
                setData(mydata);
            } catch (error) {
                console.error('Error fetching colleges:', error);
                setModalShow(false)
                setModalType("stop")
            } finally {
             
                setModalShow(false)
                setModalType("stop")
            }
        };
        

        fetchcolleges();
    },[mystatename,currentPage])
  // Go to next page

const nextPage = () => {
if (currentPage < Math.ceil(data.length / itemsPerPage)) {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0); // Scroll to the top of the page
}
};

// Go to previous page
const prevPage = () => {
if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0); // Scroll to the top of the page
}
};
// Logic to get current items


// Calculate the range of items to display
const totalItems = data.length;
const maxPages = Math.ceil(totalItems / itemsPerPage);
//const currentPageItems = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    window.scrollTo(0, 0); // Scroll to the top of the page
};
    return(
        <div>
            <Header />
            {
        modalType==="start" ?
        <ResponseModal 
        show={modalShow}
        onHide={setModalShow}
        path=""
        setModalType={setModalType}
        modalType={modalType}
          />
        :
    

            <div className="college-container">
      <h2 className="myheader">Top College's of {mystatename}</h2>

      <div className="college-card-container">
        {currentItems.map((college, index) => (

       
          <CollegeCard key={index} college={college} />
        
          
        ))}
         <div className="pagination">
                        <Button className="m-2" onClick={prevPage} disabled={currentPage === 1}>{"<"}</Button>
                        <div className="navbar-container">
                        {Array.from({ length: Math.min(maxPages, 1000) }).map((_, index) => (
                                        <Button className="m-2" key={index} onClick={() => paginate(index + 1)}>
                                            {index + 1}
                                        </Button>
                                    ))}
</div>

                        <Button className="m-2" onClick={nextPage} disabled={currentPage === Math.ceil(data.length / itemsPerPage)}>{">"}</Button>
                      </div>
      </div>

    </div>
    }
        </div>
    );
}
export default College;